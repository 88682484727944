<template>
  <section class="InputLabel">
    <label :for="inputId" class="InputLabel__label">{{ label }}</label>
  </section>
</template>

<script>
import LayoutMixin from '../mixins/layout.mixin'

export default {
  name: 'DDMCAF-InputLabel',
  mixins: [LayoutMixin],
}
</script>

<style lang="scss">
.InputLabel {
  &__label {
    display: inline-flex;
    font-weight: 600;
    font-size: 12px;
    margin-right: 20px;
    opacity: 0.9;
    line-height: 12px;
    color: #383838;
  }
  p{
    font-size: 12px;
  }
}
</style>
