import {includes} from "lodash";

export default {
  props: {
    arrangement: {type: String, default: 'horizontal', validator: value => includes(['horizontal', 'vertical'], value)},
    label: {type: String, default: ''},
    error: {type: String, required: false},
    subtext: {type: String, required: false},
    inputId: {type: String}
  }
}
