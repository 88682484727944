<template>
  <section
    :class="[
    'InputLayout',
    `InputLayout_arrangement-${arrangement}`,
    {'InputLayout_error': error}
  ]"
  >
    <div class="InputLayout__labels">
      <InputLabel
        v-if="label"
        :class="[
          'InputLayout__label',
          'InputLayout__label_main'
        ]"
        :input-id="inputId"
        :label="label"
      />
      <p
        v-if="subtext"
        :class="[
         'InputLayout__label',
         'InputLayout__label_subtext'
        ]"
      >
        {{ subtext }}
      </p>
      <div class="InputLayout__error">
        {{ error }}
      </div>
    </div>
    <div class="InputLayout__slot">
      <slot/>
    </div>
  </section>
</template>

<script>
import InputLabel from '../components/InputLabel.component';
import LayoutMixin from '../mixins/layout.mixin';

export default {
  name: 'DDMCAF-InputLayout',
  components: {InputLabel},
  mixins: [LayoutMixin]
}
</script>

<style lang="scss">
.InputLayout {
  display: flex;
  width: 100%;

  &_arrangement {
    &-vertical {
      flex-direction: column;
      align-items: flex-start;
    }

    &-horizontal {
      flex-direction: row;
      align-items: center;
    }
  }

  &__label {
    &_subtext {
      opacity: 0.9;
      color: #383838;
      font-size: 12px;
      cursor: default;
      padding: 4px 0;
    }
  }

  &__error {
    font-weight: 600;
    font-size: 8px;
    opacity: 0.9;
    line-height: 10px;
    color: red;
  }

  &_error {
    .InputLayout__slot {
      .Input__input {
        border-color: red !important
      }

      .mx-input {
        border-color: red !important
      }

      .multiselect__tags {
        border-color: red !important
      }

      .InputImage__content-box {
        border-color: red !important
      }

      .InputImage__content-image {
        border-color: red !important
      }

      .InputPhone__phone {
        border-color: red !important
      }
    }
  }
}
</style>
