var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
  'InputLayout',
  `InputLayout_arrangement-${_vm.arrangement}`,
  {'InputLayout_error': _vm.error}
]},[_c('div',{staticClass:"InputLayout__labels"},[(_vm.label)?_c('InputLabel',{class:[
        'InputLayout__label',
        'InputLayout__label_main'
      ],attrs:{"input-id":_vm.inputId,"label":_vm.label}}):_vm._e(),(_vm.subtext)?_c('p',{class:[
       'InputLayout__label',
       'InputLayout__label_subtext'
      ]},[_vm._v(" "+_vm._s(_vm.subtext)+" ")]):_vm._e(),_c('div',{staticClass:"InputLayout__error"},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1),_c('div',{staticClass:"InputLayout__slot"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }